import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutUser } from 'app/auth/store/userSlice';

function UserMenu(props) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);

	const [userMenu, setUserMenu] = useState(null);

	const userMenuClick = event => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	return (
		user.user_role && (
			<>
				<Button className="min-h-40" onClick={userMenuClick}>
					<div className="hidden md:flex flex-col mx-4 items-end">
						<Typography component="span" className="normal-case font-bold flex">
							{user.data.displayName}
						</Typography>
						<Typography className="text-11 capitalize" color="textSecondary">
							{user.user_role.toString()}
							{(!user.user_role || (Array.isArray(user.user_role) && user.user_role.length === 0)) &&
								'Guest'}
						</Typography>
					</div>

					{user.data.photoURL ? (
						<Avatar className="mx-4" alt="user photo" src={user.data.photoURL} />
					) : (
						<Avatar className="mx-4">{user.data.displayName[0]}</Avatar>
					)}
				</Button>

				<Popover
					open={Boolean(userMenu)}
					anchorEl={userMenu}
					onClose={userMenuClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
					classes={{
						paper: 'py-8'
					}}
				>
					{!user.user_role || user.user_role.length === 0 ? (
						<>
							<MenuItem component={Link} to="/login" role="button">
								<ListItemIcon className="min-w-40">
									<Icon>lock</Icon>
								</ListItemIcon>
								<ListItemText primary="Login" />
							</MenuItem>
							<MenuItem component={Link} to="/register" role="button">
								<ListItemIcon className="min-w-40">
									<Icon>person_add</Icon>
								</ListItemIcon>
								<ListItemText primary="Register" />
							</MenuItem>
						</>
					) : (
						<>
							{/* <MenuItem component={Link} to="/pages/profile" onClick={userMenuClose} role="button">
								<ListItemIcon className="min-w-40">
									<Icon>account_circle</Icon>
								</ListItemIcon>
								<ListItemText primary="My Profile" />
							</MenuItem>
							<MenuItem component={Link} to="/apps/mail" onClick={userMenuClose} role="button">
								<ListItemIcon className="min-w-40">
									<Icon>mail</Icon>
								</ListItemIcon>
								<ListItemText primary="Inbox" />
							</MenuItem> */}
							<MenuItem
								onClick={() => {
									console.log('user role logout');
									dispatch(logoutUser());
									userMenuClose();
								}}
							>
								<ListItemIcon className="min-w-40">
									<Icon>exit_to_app</Icon>
								</ListItemIcon>
								<ListItemText primary="Logout" />
							</MenuItem>
						</>
					)}
				</Popover>
			</>
		)
	);
}

export default UserMenu;
